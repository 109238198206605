@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* styles.css atau styles.scss */

.slide-appear,
.slide-enter {
  transform: translateX(100%);
  opacity: 0.01;
}

.slide-appear-active,
.slide-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
}

.slide-exit {
  transform: translateX(0);
  opacity: 1;
}

.slide-exit-active {
  transform: translateX(-100%);
  opacity: 0.01;
  transition: transform 500ms ease-in-out, opacity 500ms ease-in-out;
}

@keyframes loading {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

.animate-loading {
  animation: 1.25s cubic-bezier(0, 0, .2, 1) infinite loading;
}